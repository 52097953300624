import React from 'react'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.error('@getDerivedStateFromError', error)

    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo)
    console.error(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className='block rounded-md bg-red-500 p-2 text-white'>
          <h2>Oops! This Component Crashed!</h2>
        </div>
      )
    }

    // return <>{this.props.children}</>
    return this.props.children
  }
}

export default ErrorBoundary
