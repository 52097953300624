// ------------- Orders Reducer -------------
import { createSlice } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'

// #1 Initial state
const initialState = {
  sales: [], // as a host I want to see all orders made to my events (i.e. Sale Orders)
  purchases: [], // as a user I want to see all orders I've made (i.e. Purchase Orders)
  scheduledOrders:[], //as a host I want to see all orders within the specific date range (i.e. Scheduled Orders)
}

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setSaleOrders: (state, action) => ({ ...state, sales: action?.payload || [] }),

    // setPurchaseOrders: (state, action) => ({ ...state, purchases: action?.payload || [] }),
    setPurchaseOrders: (state, action) => ({ ...state, purchases: action?.payload || [] }),
    //
    setScheduledOrders: (state, action) => ({ ...state, scheduledOrders: action?.payload || [] }),
    resetScheduledOrders: state => ({ ...state, scheduledOrders: [] }),
    resetSaleOrders: state => ({ ...state, sales: [] }),
    resetPurchaseOrders: state => ({ ...state, purchases: [] }),
    resetOrders: () => initialState,
  },

  // Special reducer for hydrating the state
  // extraReducers: {
  //   [HYDRATE]: (state, action) => {
  //     return {
  //       ...state,
  //       ...action.payload.comments,
  //     }
  //   },
  // },

  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, action) => {
      return {
        ...state,
        ...action.payload.comments,
      }
    })
  },
})

// Action creators are generated for each case reducer function
export const {
  setSaleOrders,
  setPurchaseOrders,
  setScheduledOrders,
  resetScheduledOrders,
  resetOrders,
  resetSaleOrders,
  resetPurchaseOrders,
} = ordersSlice.actions

export default ordersSlice.reducer
