// import { Analytics as VercelAnalytics } from '@vercel/analytics/react'
import ProductionContainer from 'components/containers/ProductionContainer'
import Head from 'next/head'

const Analytics = () => {
  const GOOGLE_ANALYTICS_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID
  const GOOGLE_TAG_MANAGER_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID

  return (
    <ProductionContainer>
      <Head>
        {/* <!-- Google Analytics  --> */}
        {GOOGLE_ANALYTICS_ID && (
          <>
            <script
              async
              id='GOOGLE_ANALYTICS_ID'
              src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_ID}`}
            />

            <script
              dangerouslySetInnerHTML={{
                __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${GOOGLE_ANALYTICS_ID}');`,
              }}
            />
          </>
        )}

        {/* <!-- Google Tag manager  --> */}
        {GOOGLE_TAG_MANAGER_ID && (
          <script
            id='GOOGLE_TAG_MANAGER_ID'
            dangerouslySetInnerHTML={{
              __html: `
            (function (w, d, s, l, i) {
            w[l] = w[l] || []; w[l].push({
            'gtm.start':
            new Date().getTime(), event: 'gtm.js'
            }); var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', '${GOOGLE_TAG_MANAGER_ID}');`,
            }}></script>
        )}
      </Head>

      {/* Microsoft Clarity */}
      <script
        type='text/javascript'
        dangerouslySetInnerHTML={{
          __html: `
            (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "nex3zsck1y");
        `,
        }}></script>

      {/* https://vercel.com/docs/concepts/analytics/quickstart */}
      {/* <VercelAnalytics /> */}
    </ProductionContainer>
  )
}

export default Analytics
