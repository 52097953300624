import cn from 'clsx'
import withErrorBoundary from 'components/templates/WithErrorBoundary'

const MainContainer = ({ children, className }) => {
  return (
    <main
      className={cn(
        'flex flex-col',
        // 'md:gap-18 gap-10',
        // 'pb-10 pt-12 md:pb-20',
        // accessToken && 'md:pl-16  ',
        'lg:gap-18 gap-10 md:gap-16',
        'py-16', // For Header on Desktop View
        className
      )}
      id='AppMain'>
      {children}
    </main>
  )
}

export default withErrorBoundary(MainContainer)
