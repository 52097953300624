import MainContainer from 'components/containers/MainContainer'
import Footer from 'components/organisms/nav/Footer'
import Header from 'components/organisms/nav/Header'
import React from 'react'

const DefaultLayout = ({ children }) => {
  return (
    <>
      <Header />
      <MainContainer>{children}</MainContainer>
      <Footer />
    </>
  )
}

export default DefaultLayout
