import { getBaseUrlFor } from 'utils/Url'

import { Facebook, Instagram, LinkedIn } from './Icons'
import ROUTES from './Routes'

const FOOTER_LINKS = {
  HOSTING: [
    {
      label: 'Host your events',
      link: ROUTES.HOSTING.HOST_YOUR_EVENT,
    },
    {
      label: 'Sell tickets online',
      link: ROUTES.HOSTING.SELL_TICKETS_ONLINE,
    },
    {
      label: 'Host Responsibly',
      link: ROUTES.HOSTING.HOST_RESPONSIBLY,
    },
    // {
    //   label: 'Hosting Resources',
    //   link: ROUTES.HOSTING.HOSTING_RESOURCES,
    // },
    {
      label: 'Event Planning',
      // link: '/',
      link: ROUTES.HOSTING.EVENT_PLANNING,
    },
  ],
  FEATURES: [
    {
      label: 'Pricing',
      link: ROUTES.FEATURES.PRICING_COMPARISON,
    },
    {
      label: 'Ticketing',
      link: ROUTES.FEATURES.TICKETING,
    },
    {
      label: 'Attendee Management',
      link: ROUTES.FEATURES.ATTENDEE_MANAGEMENT,
    },
    {
      label: 'Growth and Marketing',
      link: ROUTES.FEATURES.GROWTH_AND_MARKETING,
    },
  ],
  SESSAMI: [
    {
      label: 'About Us',
      link: getBaseUrlFor('marketplace') + ROUTES.ABOUT_US,
      external: true,
    },
    {
      label: 'Contact',
      link: getBaseUrlFor('marketplace') + ROUTES.CONTACT,
      external: true,
    },
    {
      label: 'Careers',
      link: getBaseUrlFor('marketplace') + ROUTES.SESSAMI.CAREERS,
      external: true,
    },
    {
      label: 'Investors',
      link: getBaseUrlFor('marketplace') + ROUTES.SESSAMI.INVESTORS,
      external: true,
    },
  ],
  RESOURCES: [
    // {
    //   label: 'Blogs',
    //   link: getBaseUrlFor('marketplace')+ROUTES.RESOURCES.BLOG,
    // external: true,
    // },
    {
      label: 'Help',
      link: ROUTES.RESOURCES.HELP,
    },
    {
      label: 'FAQs',
      link: getBaseUrlFor('marketplace') + ROUTES.RESOURCES.FAQS,
      external: true,
    },
    // {
    //   label: 'News Center',
    //   link: getBaseUrlFor('marketplace')+ROUTES.RESOURCES.NEWS_CENTER,
    // external: true,
    // },
    {
      label: 'SiteMap',
      link: getBaseUrlFor('marketplace') + ROUTES.SITEMAP,
      external: true,
    },
  ],

  LEGAL: [
    {
      label: 'Terms of Use',
      link: getBaseUrlFor('marketplace') + ROUTES.LEGAL.TERMS_OF_USE,
      external: true,
    },
    {
      label: 'Privacy Policy',
      link: getBaseUrlFor('marketplace') + ROUTES.LEGAL.PRIVACY_POLICY,
      external: true,
    },
    {
      label: 'Cookie Policy',
      link: getBaseUrlFor('marketplace') + ROUTES.LEGAL.COOKIE_POLICY,
      external: true,
    },
    {
      label: 'Billing and Refunds',
      link: getBaseUrlFor('marketplace') + ROUTES.LEGAL.BILLING,
      external: true,
    },
    {
      label: 'Verification ',
      link: getBaseUrlFor('marketplace') + ROUTES.LEGAL.BADGE_VERIFICATION_REQUIREMENTS,
      external: true,
    },
  ],

  SOCIAL: [
    // {
    //   label: 'Whatsapp',
    //   link: 'https://wa.me/447850153604',
    // },
    {
      label: 'Facebook',
      link: 'https://www.facebook.com/sessamiAPP/',
      // icon: <FaFacebook size={24} />,
      icon: <Facebook size={24} />,
      external: true,
    },
    // {
    //   label: 'Youtube',
    //   link: '/',
    // },
    {
      label: 'Instagram',
      link: 'https://www.instagram.com/sessamiapp/',
      icon: <Instagram size={24} />,
      external: true,
    },
    {
      label: 'LinkedIn',
      link: 'https://www.linkedin.com/company/sessami',
      icon: <LinkedIn size={24} />,
      external: true,
    },
  ],
}

export default FOOTER_LINKS
