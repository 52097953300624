import Head from 'next/head'

const GlobalMeta = () => (
  <Head>
    {/* PWA */}
    <meta name='application-name' content='Sessami' />
    <meta name='apple-mobile-web-app-capable' content='yes' />
    <meta name='apple-mobile-web-app-status-bar-style' content='default' />
    <meta name='apple-mobile-web-app-title' content='Sessami' />
    <meta name='format-detection' content='telephone=no' />
    <meta name='mobile-web-app-capable' content='yes' />
    {/* <meta name='msapplication-config' content='/icons/browserconfig.xml' /> */}
    <meta name='msapplication-TileColor' content='#cf4368' />
    <meta name='msapplication-tap-highlight' content='no' />
    <meta name='theme-color' content='#cf4368' />

    <link rel='apple-touch-icon' href='/icons/icon-192.png' />
    <link rel='apple-touch-icon' sizes='192x192' href='/icons/icon-192.png' />
    <link rel='apple-touch-icon' sizes='384x384' href='/icons/icon-384.png' />
    <link rel='apple-touch-icon' sizes='512x512' href='/icons/icon-512.png' />

    <link rel='icon' type='image/png' sizes='32x32' href='/icons/icon-32.png' />
    <link rel='icon' type='image/png' sizes='16x16' href='/icons/icon-16.png' />
    <link rel='manifest' href='/manifest.json' />
    <link rel='mask-icon' href='/icons/icon-192.png' />
    <link rel='shortcut icon' href='/icons/icon-192.png' />
  </Head>
)

export default GlobalMeta
