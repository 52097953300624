// ------------- campaign Reducer -------------
import { createSlice } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'

// #1 Initial state
const initialState = {
  myCampaigns: [],
  emailDetails: {},
  formStep: 1,
  recipients: [],
  campaignId: '',
  designs: [], // as a host I want to see all the campaigns
}

export const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    setEditorDesign: (state, action) => ({ ...state, designs: action?.payload || [] }),
    setMyCampaigns: (state, action) => ({ ...state, myCampaigns: action?.payload }),
    setEmailDetails: (state, action) => ({ ...state, emailDetails: action?.payload || [] }),
    setFormStep: (state, action) => ({ ...state, formStep: action?.payload || [] }),
    setRecipients: (state, action) => ({ ...state, recipients: action?.payload || [] }),
    setCampaignId: (state, action) => ({ ...state, campaignId: action?.payload || [] }),
    resetCampaigns: () => initialState,
  },

  // Special reducer for hydrating the state
  // extraReducers: {
  //   [HYDRATE]: (state, action) => {
  //     return {
  //       ...state,
  //       ...action.payload.comments,
  //     }
  //   },
  // },

  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    })
  },
})

// Action creators are generated for each case reducer function
export const {
  setEditorDesign,
  resetCampaigns,
  setEmailDetails,
  setFormStep,
  setRecipients,
  setMyCampaigns,
  setCampaignId,
} = campaignSlice.actions

export default campaignSlice.reducer
