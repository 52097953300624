import { EURO_COUNTRIES, SUPPORTED_COUNTRIES } from 'consts'
import { useDispatch, useSelector } from 'react-redux'
import { setExchange, setVisitorIP, setVisitorIPCountry } from 'store/reducers'
import { customRound } from 'utils/Pricing'

export default function useCountryLookup() {
  const dispatch = useDispatch()
  const exchange = useSelector(s => s?.pr?.ui?.exchange)
  const visitorIPCountry = useSelector(s => s?.pr?.ui?.visitorIPCountry)
  const currencyApiKey = process.env.NEXT_PUBLIC_CURRENCY_API_KEY || ''
  const getVisitorIP = async () => {
    fetch('https://api.ipify.org/?format=json')
      .then(response => response.json())
      .then(data => {
        dispatch(setVisitorIP(data?.ip))
        getCountry(data?.ip)
      })
      .catch(err => console.error('@getVisitorIP', err))
  }

  // cascaded call from above
  const getCountry = async visitorIP => {
    fetch(`https://api.country.is/${visitorIP}`)
      .then(response => response.json())
      .then(data => dispatch(setVisitorIPCountry(data.country)))
      .catch(err => console.error('@getCountry', err))
  }

  const fetchExchange = async () => {
    getVisitorIP()

    if (!SUPPORTED_COUNTRIES.includes(visitorIPCountry)) return 1 // this includes UK

    const fetchURL = `https://api.currencyapi.com/v3/latest?apikey=${currencyApiKey}&currencies=EUR%2CUSD&base_currency=GBP`

    fetch(fetchURL)
      .then(response => response.json())
      .then(res => dispatch(setExchange(res.data)))
      .catch(err => console.error('@fetchExchange:', err))
  }

  const fetchConversion = () => {
    if (
      exchange === 1 &&
      (visitorIPCountry === 'US' || EURO_COUNTRIES.includes(visitorIPCountry))
    ) {
      return 1
    } else if (visitorIPCountry === 'US') {
      return exchange?.USD?.value || 'loading...'
    } else if (EURO_COUNTRIES.includes(visitorIPCountry)) {
      return exchange?.EUR?.value || 'loading...'
    }
    return 1
  }

  // Support for UK, US and Euro countries
  const getPriceLocale = (price, omitUK = false) => {
    if (omitUK) return

    const convertedPrice = price * fetchConversion()
    const roundedPrice = customRound(convertedPrice) // number
    const priceWithMandatoryPenceSuffix = parseFloat(roundedPrice).toFixed(2)

    // let formattedPrice = `£${Number(roundedPrice)}`
    let formattedPrice = `£${priceWithMandatoryPenceSuffix}` // string

    if (
      exchange === 1 &&
      (visitorIPCountry === 'US' || EURO_COUNTRIES.includes(visitorIPCountry))
    ) {
      return formattedPrice
    } else if (visitorIPCountry === 'US') {
      formattedPrice = `$${roundedPrice}`
    } else if (EURO_COUNTRIES.includes(visitorIPCountry)) {
      if (isNaN(roundedPrice)) {
        formattedPrice = `£${price}`
      } else {
        formattedPrice = `${roundedPrice}€`
      }
    }

    return formattedPrice
  }

  const getPriceLocaleOnEventCards = (price = 0, omitUK = false) => {
    if (omitUK) return

    if (price === 0) return 'Free'

    return getPriceLocale(price, omitUK)
  }

  return {
    getVisitorIP,
    getCountry,
    visitorIPCountry,
    fetchExchange,
    fetchConversion,
    getPriceLocale,
    getPriceLocaleOnEventCards,
  }
}
