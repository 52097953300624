import cn from 'clsx'
import withErrorBoundary from 'components/templates/WithErrorBoundary'

const Container = ({ className = '', reference = null, children }) => {
  return (
    <div className={cn('w-full px-0 md:px-4 lg:px-8', 'mx-auto', className)} ref={reference}>
      {children}
    </div>
  )
}

export default withErrorBoundary(Container)
