import { RxCross2 } from '@react-icons/all-files/rx/RxCross2'
import Button from 'components/atoms/Button'
import { MSG, UNVERIFIED_EMAIL } from 'consts'
import { sendEmailVerification } from 'firebase/auth'
import useGlobalLoader from 'hooks/useGlobalLoader'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { auth } from 'services/FirebaseClient'

const UnVerifiedBanner = () => {
  const { loading } = useGlobalLoader()

  const [user, setUser] = useState(null)

  // State to track the showing and hiding of banner component
  const [isBannerShown, setIsBannerShown] = useState(true)

  const isUserVerified = user && user.emailVerified

  useEffect(() => {
    const fetchUserFromAuth = auth?.onAuthStateChanged(authUser => {
      setUser(authUser)
    })

    return () => {
      fetchUserFromAuth()
    }
  }, [])

  const handleSendVerificaitonOnClick = async () => {
    loading.start()
    try {
      await sendEmailVerification(user)
      toast.info(MSG.INFO.GUEST.VERIFY_EMAIL, { toastId: 'verify-email' })
    } catch (err) {
      // If requested too many times, throws an error called too-many-requests
      if (err.code === 'auth/too-many-requests') {
        // Giving a suitable message
        toast.error(MSG.ERROR.GUEST.TRY_AGAIN, { toastId: 'try-again' })
      } else {
        toast.error(err?.message, { toastId: 'error-message' })
      }
    } finally {
      loading.remove()
    }
  }

  if (!user || isUserVerified || !isBannerShown) return null

  return (
    <div className='flex col-span-full flex-wrap items-center justify-center gap-2 bg-primary p-1 sm:justify-center'>
      <p className='font-medium text-primary-content max-md:text-sm'>{UNVERIFIED_EMAIL}</p>
      <div className='flex items-center gap-1'>
        <Button
          secondary
          className='btn-xs text-xs font-semibold'
          onClick={handleSendVerificaitonOnClick}>
          Resend
        </Button>
        <Button
          className='btn-ghost btn-xs text-xs font-semibold text-primary-content'
          onClick={() => setIsBannerShown(false)}>
          <RxCross2 size={20} />
        </Button>
      </div>
    </div>
  )
}

export default UnVerifiedBanner
