import cn from 'clsx'
import Image from 'components/atoms/Image'
import Container from 'components/containers/Container'
import GridContainer from 'components/containers/GridContainer'
import FOOTER_LINKS from 'consts/Footer'
import Link from 'next/link'
import React from 'react'
import { useSelector } from 'react-redux'

const FooterLists = ({ title, linksArr, className, direction = 'col', iconsOnly }) => {
  if (!linksArr || !Array.isArray(linksArr)) return null

  return (
    <ul
      className={cn(
        `col-span-full flex ${
          iconsOnly ? 'flex-row' : 'flex-col'
        }  gap-2 place-self-start md:col-span-2 md:gap-4`,
        direction === 'col' ? 'md:flex-col' : 'md:flex-row',
        className
      )}>
      {title && <li className='mb-2 text-xl font-bold'>{title}</li>}

      {linksArr?.map(i => (
        <li
          className={cn(
            'text-left text-base font-medium hover:underline',
            i.link === '/' || i.link === '#'
              ? 'tooltip tooltip-right text-base-300 md:tooltip-left'
              : ''
          )}
          data-tip='Coming Soon'
          key={i.label}>
          {i?.external ? (
            <a href={i.link} target='_blank' rel='noreferrer'>
              {iconsOnly ? i?.icon : i?.label}
            </a>
          ) : (
            <Link href={i.link}>
              {iconsOnly ? i?.icon : i?.label}
              {/* {i.label} */}
            </Link>
          )}
        </li>
      ))}
    </ul>
  )
}

const Footer = () => {
  const accessToken = useSelector(s => s?.pr?.user?.auth?.stsTokenManager?.accessToken)
  const isAppUI = useSelector(s => s?.pr?.ui?.isAppUI)
  const theme = useSelector(s => s?.pr?.ui?.theme)

  if (isAppUI) return null

  return (
    <footer
      className={cn(
        'bg-base-200 py-16 dark:bg-base-200',
        accessToken ? 'hidden md:flex' : 'flex', // don't show on mobile view for logged in users
        'flex-col gap-4 md:gap-12',
        'custom-transition-a'
      )}>
      {/* First Row */}
      {/* Icon */}
      <Container>
        <GridContainer className='flex flex-col items-center justify-center md:flex-row'>
          <div className='col-span-full md:col-span-4'>
            <Link href='/'>
              <Image
                src='/assets/icons/logo-type-black.png'
                width={200}
                height={50}
                className={cn(theme === 'dark' ? 'invert filter' : 'invert-0')}
                alt='Sessami Logotype Black'
              />
            </Link>
          </div>

          {/* Hosting Events */}
          <FooterLists
            title='Hosting Events'
            linksArr={FOOTER_LINKS.HOSTING}
            className=' md:col-start-5'
          />

          {/* Features */}
          <FooterLists
            title='Features'
            linksArr={FOOTER_LINKS.FEATURES}
            className='md:col-start-7'
          />

          {/* Sessami */}
          <FooterLists title='Sessami' linksArr={FOOTER_LINKS.SESSAMI} className='md:col-start-9' />

          {/* Resources */}
          <FooterLists
            title='Resources'
            linksArr={FOOTER_LINKS.RESOURCES}
            className='md:col-start-11'
          />
        </GridContainer>
      </Container>

      {/* Second row */}
      <Container>
        <GridContainer>
          <div className='col-span-full flex flex-col gap-4 md:flex-row md:justify-between'>
            <FooterLists linksArr={FOOTER_LINKS.LEGAL} direction='row' />
            <FooterLists linksArr={FOOTER_LINKS.SOCIAL} direction='row' iconsOnly={true} />
          </div>
        </GridContainer>
      </Container>
    </footer>
  )
}

export default Footer
