import { AiFillBug } from '@react-icons/all-files/ai/AiFillBug'
import cn from 'clsx'
import ErrorBoundary from 'components/containers/ErrorBoundary'
import useCountryLookup from 'hooks/useCountryLookup'
import useKeyPress from 'hooks/useKeyPress'
import Link from 'next/link'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setShowDebug } from 'store/reducers'

const Debug = () => {
  const { visitorIPCountry, fetchConversion } = useCountryLookup()
  const platform = useSelector(s => s?.pr?.ui?.platform)
  const showDebug = useSelector(s => s?.pr?.ui?.showDebug)
  const hostTitle = useSelector(s => s?.pr?.user?.info?.hostTitle)
  const userEmail = useSelector(s => s?.pr?.user?.info?.email)
  const userType = useSelector(s => s?.pr?.user?.info?.userType)
  const userId = useSelector(s => s?.pr?.user?.info?.id)
  const userLocation = useSelector(s => s?.pr?.user?.info?.userLocation)
  const trafficSource = useSelector(s => s?.pr?.ui?.trafficSource)
  // const accessToken = useSelector(s => s?.pr?.user?.auth?.stsTokenManager?.accessToken)
  const scrollPosition = useSelector(s => s?.pr?.ui?.scrollPosition)

  const dispatch = useDispatch()

  // Track multi-key press
  const ctrlPress = useKeyPress('control') // key - ctrl
  const gPress = useKeyPress('d') // key - g

  useEffect(() => {
    if (ctrlPress && gPress) dispatch(setShowDebug(!showDebug))
  }, [ctrlPress, gPress])

  const table = [
    { key: '📱', val: platform },
    { key: '🚩', val: visitorIPCountry },
    { key: '💸', val: fetchConversion() },
    { key: 'NODE_ENV', val: process.env.NODE_ENV },
    { key: 'APP_ENV (ovverride)', val: process.env.NEXT_PUBLIC_APP_ENV },
    { key: 'Host Title', val: hostTitle },
    { key: 'userId', val: userId },
    { key: 'userEmail', val: userEmail },
    { key: 'userType', val: userType },
    { key: 'trafficSource', val: trafficSource },
    { key: '🧭', val: `${userLocation?.lat}, ${userLocation?.lng}` },
    { key: 'scrollY', val: `${scrollPosition}` },
    { key: '🔥', val: process.env.NEXT_PUBLIC_FB_APIKEY || 'mock_firebase_api_key' },
  ]

  const debugPages = [{ key: 'Grid System', path: '/x-grid' }]

  const DebugInfoTable = () => (
    <div
      className={cn(
        'fixed z-50 rounded-xl  bg-base-300  p-2',
        'bottom-1/2 right-1/2 translate-x-1/2 translate-y-1/2 transform',
        'scale-75 md:scale-90',
        'border-2 border-error',
        showDebug ? 'block' : 'hidden'
      )}>
      <table className='table-compact table w-full  '>
        <thead>
          <tr>
            <th scope='col'>Key</th>
            <th scope='col' className='flex flex-row items-center justify-between'>
              <span>Val</span>
              <button
                className='btn btn-outline btn-error btn-sm'
                onClick={() => dispatch(setShowDebug(!showDebug))}>
                X
              </button>
            </th>
          </tr>
        </thead>

        <tbody>
          {table.map((i, idx) => (
            <tr key={idx}>
              <td>{i.key}</td>
              <td>{i.val}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* Debugging Pages */}
      <div>
        {debugPages.map((i, index) => (
          <Link className='btn' key={index} href={i.path}>
            {i.key}
          </Link>
        ))}
      </div>
    </div>
  )

  return (
    <ErrorBoundary>
      {/* Disabling UI button in favor of Ctrl+D */}

      <button
        className={cn(
          'btn fixed -left-4 bottom-20 z-50 rounded-r-xl  ',
          showDebug ? 'btn-error' : 'btn-ghost',
          process.env.NEXT_PUBLIC_APP_ENV === 'production' ? 'hidden' : 'block'
        )}
        onClick={() => dispatch(setShowDebug(!showDebug))}>
        <AiFillBug size={20} />
      </button>

      <DebugInfoTable />
    </ErrorBoundary>
  )
}

export default Debug
