import ErrorBoundary from 'components/containers/ErrorBoundary'
import React from 'react'

// HOC - Higher Order Component
const withErrorBoundary = WrappedComponent => {
  // eslint-disable-next-line
  return function WithErrorBoundary(props) {
    return (
      <ErrorBoundary>
        <WrappedComponent {...props} />
      </ErrorBoundary>
    )
  }
}

export default withErrorBoundary
