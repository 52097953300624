import MSG from 'consts/Messages'
import { toast } from 'react-toastify'

export const sendSlackNotification = (
  data,
  source,
  showToast = true,
  webhookURL = process.env.NEXT_PUBLIC_NOTIFY_CUSTOMERS_WEBHOOK_URL
) => {
  let text = source ? `Message from ${source}\n` : ''

  if (data.message) text += `${data.message}\n`
  else {
    // loop and concatenate the form data into a single string
    for (let key in data) {
      text += `${key.toUpperCase()}: ${data[key]}\n`
    }
  }

  // suffix a timestamp to the message
  text = text + `${new Date().toString()}\n`

  // don't add content-type headers, otherwise you will get a CORS error
  const payload = { method: 'POST', body: JSON.stringify({ text }) || {} }

  fetch(webhookURL, payload)
    .then(res => {
      console.log('res', res)

      if (showToast) {
        if (res.ok) toast.success(MSG.SUCCESS.BUYER.SLACK_NOTIFY, { toastId: 'notify-slack' })
        else toast.error(MSG.ERROR.BUYER.SLACK_NOTIFY_ERR, { toastId: 'notify-slack-error' })
      }
    })
    .catch(err => console.error('Error submitting Contact form to slack:', err))
}
