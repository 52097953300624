// Custom middleware to
// - show loading indicator whenever any action is dispatched
// - hide loading indicator after the state is updated
export const loaderUIMiddleware = () => next => action => {
  // Start loading
  // Loading.standard()

  // Proceed with the action
  const result = next(action)

  // Stop loading after state update
  // Loading.remove()

  return result
}
