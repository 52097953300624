export const ADMIN_BASE_URL = {
  DEVELOPMENT: 'https://dev.admin.sessami.co',
  BETA: 'https://beta.admin.sessami.co',
  PRODUCTION: 'https://admin.sessami.co',
}

export const MARKETPLACE_BASE_URL = {
  DEVELOPMENT: 'https://dev.sessami.co',
  BETA: 'https://beta.sessami.co',
  PRODUCTION: 'https://sessami.co',
}

export const PARTNERS_BASE_URL = {
  DEVELOPMENT: 'https://dev.partners.sessami.co',
  BETA: 'https://beta.partners.sessami.co',
  PRODUCTION: 'https://partners.sessami.co',
}

// TODO: Incorporate this with proper testing later
export const CLOUD_FUNCTIONS_BASE_URL = {
  EMULATOR: 'http://127.0.0.1:5001/sessami-58c65/us-central1',
  DEVELOPMENT: 'https://us-central1-sessami-58c65.cloudfunctions.net',
  PRODUCTION: 'https://us-central1-sessami-production.cloudfunctions.net',
}

export const BOOK_A_MEETING_WITH_FOUNDERS_URL = 'https://calendar.app.google/XTscfVaSLHguB97u6'
