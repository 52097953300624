import MSG from 'consts/Messages'
import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  query,
  serverTimestamp,
  setDoc,
  where,
} from 'firebase/firestore'
import { toast } from 'react-toastify'
import { db } from 'services/FirebaseClient'

// #############################################################################
// #                                CREATE                                     #
// #############################################################################
export const createCampaign = async (campaignId, payload) => {
  const docRef = doc(db, 'campaigns', campaignId)

  try {
    await setDoc(docRef, { ...payload, createdAt: serverTimestamp() })
  } catch (err) {
    console.log('Error creating campaign', err)
    throw err
  }
}
export const readCampaignsByHostId = async (hostId, callback = () => {}) => {
  if (!hostId) return null

  const campaignRef = query(collection(db, 'campaigns'), where('hostId', '==', hostId))

  try {
    onSnapshot(campaignRef, querySnapshot => {
      const campaigns = querySnapshot.docs.map(doc => doc.data())
      callback(campaigns)
    })
  } catch (error) {
    console.error('Error @getCampaignsTemplateBySellerId', error)
  }
}

// #############################################################################
// #                                DELETE                                     #
// #############################################################################
export const deleteCampaignById = async templateId => {
  if (!templateId) return
  const docRef = doc(db, 'campaigns', templateId)
  await deleteDoc(docRef)
    .then(() => {
      toast.success(MSG.SUCCESS.SELLER.CAMPAIGN.DELETED, { toastId: 'campaign-deleted' })
    })
    .catch(e => {
      toast.error(MSG.ERROR.SELLER.CAMPAIGN.CAMPAIGN_DELETION_FAILED, {
        toastId: 'campaign-deletion-failed',
      })
      console.log('error in event deletion::', e)
    })
}
