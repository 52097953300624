import { BsArrowLeftCircle } from '@react-icons/all-files/bs/BsArrowLeftCircle'
import cn from 'clsx'
import Image from 'components/atoms/Image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setTitleBar } from 'store/reducers'

// const logoTypeBlack = '/assets/icons/logo-type-black.svg'
const logoTypeBlack = '/assets/icons/icon-512.png'

const BackBar = () => {
  const router = useRouter()
  const dispatch = useDispatch()

  const accessToken = useSelector(s => s?.pr?.user?.info?.accessToken)
  const titleBar = useSelector(s => s?.pr?.ui?.titleBar)

  const handleBackPress = () => {
    dispatch(setTitleBar({}))
    router.push(titleBar?.backlink)
  }

  return (
    <div className='custom-transition-a col-span-1 flex cursor-pointer items-center justify-between gap-2 rounded-xl  lg:col-span-6 lg:space-x-4'>
      {accessToken ? (
        <>
          <BsArrowLeftCircle
            size={24}
            onClick={handleBackPress}
            className={cn(
              titleBar?.backlink ? 'block' : 'hidden',
              'rounded-full text-base-content hover:text-secondary lg:hidden'
            )}
          />
          <h2 className='flex flex-1 items-center gap-2 text-center text-lg font-bold md:gap-4'>
            {titleBar?.title}
          </h2>
        </>
      ) : (
        <Link className='flex cursor-pointer flex-row items-center gap-2 md:ml-4' href='/'>
          <Image
            src={logoTypeBlack}
            alt='Icon White'
            width={24}
            height={24}
            className={cn(
              'h-6 w-6'
              // 'md:hidden' // hide on desktop as Icon is already in Vertical NavBar
            )}
          />
          <h1 className='text-lg font-semibold uppercase'>Partners Studio</h1>
        </Link>
      )}
    </div>
  )
}

export default BackBar
