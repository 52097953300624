// #                                SELLER                                     #
// #############################################################################
import { SALES_ORDERS_FETCH_LIMIT } from 'consts/Firebase'
import MSG from 'consts/Messages'
import {
  collection,
  doc,
  endBefore,
  getCountFromServer,
  getDocs,
  limit,
  limitToLast,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  startAt,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore'
import { toast } from 'react-toastify'
import { Timestamp, db } from 'services/FirebaseClient'

// #############################################################################
// #                                CREATE                                     #
// #############################################################################

// #############################################################################
// #                                READ                                       #
// #############################################################################

async function executeQuery(
  coll,
  baseQuery,
  nextPage,
  pageSize,
  startsAfter = null,
  rerender = false,
  orderByField = 'createdAt'
) {
  const countQuery = query(coll, ...baseQuery)

  let startPoint
  if (nextPage) {
    startPoint = rerender ? startAt(startsAfter) : startAfter(startsAfter)
  } else {
    startPoint = endBefore(startsAfter)
  }

  const q = query(
    coll,
    ...baseQuery,
    orderBy(orderByField),
    startPoint,
    nextPage ? limit(pageSize) : limitToLast(pageSize)
  )

  try {
    const countSnapShot = await getCountFromServer(countQuery)
    const retrievedEventsCount = countSnapShot.data().count
    const querySnapshot = await getDocs(q)
    const allOrders = querySnapshot.docs.map(doc => doc.data()) || []
    const lastVisibleDoc = querySnapshot.docs[querySnapshot.docs.length - 1]
    const previousVisibleDoc = querySnapshot.docs[0]
    return { allOrders, lastVisibleDoc, retrievedEventsCount, previousVisibleDoc }
  } catch (error) {
    toast.error(error.message)
    console.info('error', error.message)
  }
}

export const readOrdersByEventId = async eventId => {
  if (!eventId) return

  const orderQuery = query(
    collection(db, 'orders'),
    where('eventId', '==', eventId),
    orderBy('createdAt', 'desc')
  )

  return getDocs(orderQuery)
    .then(ss => {
      let orders = ss.docs?.map(doc => doc.data()) || []

      console.log(`🛬 Orders - By EventId -${eventId}`, orders)

      return orders
    })
    .catch(err => {
      toast.error(MSG.ERROR.BUYER.FETCH_ORDER_BY_EVENTID, { toastId: 'fetch-order-failed' })
      console.error('🚩 @readOrdersByEventId', err.message)
    })
}

export const readOrdersBySellerId = async (hostId, callback = () => {}) => {
  if (!hostId) return null

  const q = query(
    collection(db, 'orders'),
    where('hostId', '==', hostId),
    // where('isCancelled', '==', false),
    orderBy('createdAt', 'desc'),
    limit(SALES_ORDERS_FETCH_LIMIT)
  )

  try {
    onSnapshot(q, querySnapshot => {
      const orders = querySnapshot.docs.map(doc => doc.data())
      callback(orders)
    })
  } catch (error) {
    console.error('Error @readOrdersBySellerId', error)
  }
}

export const getGuestsByID = async (userId, nextPage, pageSize, startsAfter = null) => {
  if (!userId) return
  const baseQuery = [where('hostId', '==', userId)]
  const coll = collection(db, 'orders')
  return executeQuery(coll, baseQuery, nextPage, pageSize, startsAfter)
}

export const getGuestOrders = async (
  eventId,
  nextPage,
  pageSize,
  startsAfter = null,
  additionalConditions = [],
  rerender = false
) => {
  if (!eventId) return

  const baseQuery = [where('eventId', '==', eventId), ...additionalConditions]

  const coll = collection(db, 'orders')

  return executeQuery(coll, baseQuery, nextPage, pageSize, startsAfter, rerender)
}

export const getGuestOrdersByEventId = async (
  eventId,
  nextPage,
  pageSize,
  startsAfter = null,
  rerender = false
) => {
  return await getGuestOrders(eventId, nextPage, pageSize, startsAfter, [], rerender)
}

export const getGuestOrdersByCheckedIn = async (
  eventId,
  nextPage,
  pageSize,
  startsAfter = null,
  rerender = false
) => {
  return await getGuestOrders(
    eventId,
    nextPage,
    pageSize,
    startsAfter,
    [where('checkedIn', '==', true)],
    rerender
  )
}
//may be used in future
export const getGuestOrdersByNotCheckedIn = async (
  eventId,
  nextPage,
  pageSize,
  startsAfter = null
) => {
  return await getGuestOrders(eventId, nextPage, pageSize, startsAfter, [
    where('checkedIn', '==', false),
  ])
}

export const getGuestOrdersByFreeTicket = async (
  eventId,
  nextPage,
  pageSize,
  startsAfter = null,
  rerender = false
) => {
  return await getGuestOrders(
    eventId,
    nextPage,
    pageSize,
    startsAfter,
    [where('summary.grandTotal', '==', 0)],
    rerender
  )
}

export const getGuestOrdersByPaidTicket = async (
  eventId,
  nextPage,
  pageSize,
  startsAfter = null,
  orderBy = 'summary.grandTotal'
) => {
  if (!eventId) return

  const baseQuery = [where('eventId', '==', eventId), where('summary.grandTotal', '!=', 0)]
  const coll = collection(db, 'orders')

  return await executeQuery(coll, baseQuery, nextPage, pageSize, startsAfter, orderBy)
}

export const getGuestOrdersByCancelled = async (
  eventId,
  nextPage,
  pageSize,
  startsAfter = null,
  rerender = false
) => {
  return await getGuestOrders(
    eventId,
    nextPage,
    pageSize,
    startsAfter,
    [where('isCancelled', '==', true)],
    rerender
  )
}

export const getSpecificDateOrdersBySellerId = async (hostId, startDate, endDate) => {
  if (
    !hostId ||
    !startDate ||
    !endDate ||
    !(startDate instanceof Date) ||
    !(endDate instanceof Date)
  ) {
    return null
  }
  const startCreatedAt = Timestamp.fromDate(startDate)
  const endCreatedAt = Timestamp.fromDate(endDate)

  const q = query(
    collection(db, 'orders'),
    where('hostId', '==', hostId),
    where('isCancelled', '==', false),
    where('createdAt', '>=', startCreatedAt),
    where('createdAt', '<=', endCreatedAt),
    orderBy('createdAt', 'desc')
  )

  try {
    const querySnapshot = await getDocs(q)
    const orders = querySnapshot.docs.map(doc => doc.data())

    return orders
  } catch (error) {
    console.error('Error @getSpecificDateOrdersBySellerId', error)
  }
}

// #############################################################################
// #                                UPDATE                                     #
// #############################################################################
// Update the status of checkedIn in the order collection
export const updateOrderCheckInStatus = async ticket => {
  try {
    const orderDocRef = doc(db, 'orders', ticket.id)
    await updateDoc(orderDocRef, {
      checkedIn: true,
      checkInTime: new Date(),
    })
  } catch (error) {
    console.error('Error @updateOrderCheckedInStatus', error)
    toast.error(error.message)
  }
}

export const cancelOrders = async orderIds => {
  const batch = writeBatch(db)

  orderIds.forEach(docId => {
    const docRef = doc(db, 'orders', docId)
    batch.update(docRef, { isCancelled: true })
  })

  // commit is done only after all the docs are updated
  return batch
    .commit()
    .then(() => 'Orders updated successfully')
    .catch(error => {
      console.error('Error updating orders: ', error)
      throw error // Propagate the error to the next catch block
    })
}

// #############################################################################
// #                                DELETE                                     #
// #############################################################################
