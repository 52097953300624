import { TICKET_PROCESSING_FIXED, TICKET_PROCESSING_PERCENTAGE } from 'consts'

export const customRound = (number, precision = 2) => {
  if (typeof number === 'string' && !isNaN(number)) {
    number = +number // Convert numeric string to number
  }

  return +Number(number || 0).toFixed(precision)
  // return roundedNumber.toString()
}

// Pence to Pounds Conversion
export const ptp = (pence, prefix = true) => {
  return prefix ? '£' + customRound(pence / 100) : customRound(pence / 100)
}

export const poundToPence = pounds => {
  // no floating point shenanigans
  return Math.round(pounds * 100)
}

// Use this when amount includes fees and you need to add fees to it
// return x = 6% + 0.2
export const getProcessingFeesOn = amount => {
  if (amount === 0) return 0 // No fees for free tickets

  const calculatedFees = amount * (TICKET_PROCESSING_PERCENTAGE / 100) + TICKET_PROCESSING_FIXED
  return customRound(calculatedFees)
}

/*
 * (OLD) net = (gross - 0.2) * (100 / 106)
 * (CURRENT) net = gross - 0.06 * gross - 0.2
 * The Reason why newer logic is use to make the fee consistent,
 * irrespective of whether the buyer pays the fees or not.
 */
export const getNetTicketPrice = grossAmt => {
  if (grossAmt === 0) return 0 // No fees for free tickets

  const netTicketPrice = grossAmt - getProcessingFeesOn(grossAmt)
  return customRound(netTicketPrice)
}

// return x = grossAmt - netAmt
export const getProcessingFeesOnGrossTicketPrice = grossAmt => {
  if (grossAmt === 0) return 0 // No fees for free tickets

  const ourFees = grossAmt - getNetTicketPrice(grossAmt)
  return customRound(ourFees)
}

export const getGrossTicketPrice = amount => {
  if (amount === 0) return 0 // No fees for free tickets

  const buyersTotal = amount + getProcessingFeesOn(amount)
  return customRound(buyersTotal)
}

// ---------------------- TICKET PRICING ----------------------
export const getGrossRevenue = (orders, eventId) => {
  const grossRevenue =
    orders
      ?.filter(i => i.eventId === eventId)
      ?.reduce((total, i) => total + i?.paymentIntent?.amount, 0) || 0

  const sanitizedGrossRevenue = parseFloat((grossRevenue / 100).toFixed(2)) || 0

  return sanitizedGrossRevenue
}

export const getTotalProcessingFees = (orders, eventId) => {
  const netRevenue =
    orders
      ?.filter(i => i.eventId === eventId)
      ?.reduce((total, i) => total + parseFloat(i?.processingFees || 0), 0) || 0

  const sanitizedNetRevenue = parseFloat(netRevenue.toFixed(2)) || 0

  return sanitizedNetRevenue
}

//calculation of subtotal and total of the campaign fees
export const getTotalCampaignFees = (costPerEmail, emailCount, vatLiable) => {
  if (emailCount === 0) return

  const subTotalFees = customRound(emailCount * costPerEmail) || 0

  const TotalFees = customRound(subTotalFees + subTotalFees * (vatLiable / 100)) || 0
  const totalVat = customRound(subTotalFees * (vatLiable / 100)) || 0

  const totalCampaignFess = {
    subTotal: subTotalFees,
    grandTotal: TotalFees,
    vat: totalVat,
  }

  return totalCampaignFess
}

// rounding off the large number
export const roundToBase1000 = (number, prefix = '') => {
  const _prefix = prefix ? prefix + ' ' : ''
  if (!number) return `${_prefix}0`

  if (number < 1000) {
    return `${_prefix}${customRound(number, 0)}`
  }

  const suffixes = ['', 'k', 'M', 'B', 'T']
  const i = Math.floor(Math.log10(number) / 3)
  const scaled = number / Math.pow(1000, i)

  return `${_prefix}${scaled.toFixed(1)}${suffixes[i]}`
}
