import {
  ADMIN_BASE_URL,
  CLOUD_FUNCTIONS_BASE_URL,
  MARKETPLACE_BASE_URL,
  PARTNERS_BASE_URL,
} from 'consts/Urls'

export const getQueryParams = (query, excludedDynamicRoute) => {
  // Loop over router.query object
  // Create a JSON object with just the query parameters excluding dynamic routes like [`eventId`]
  const queryParams = Object.keys(query).reduce((acc, key) => {
    if (excludedDynamicRoute.indexOf(key) < 0) acc[key] = query[key]

    return acc
  }, {})

  return queryParams
}

export const getQueryString = (query, excludedDynamicRoute) => {
  const queryParamsJSON = getQueryParams(query, excludedDynamicRoute)
  const searchParams = new URLSearchParams(queryParamsJSON).toString()

  return searchParams
}

export const getBaseUrlFor = appName => {
  const env = process.env.NEXT_PUBLIC_APP_ENV //  development, production
  let urlConfig

  switch (appName) {
    case 'admin':
      urlConfig = ADMIN_BASE_URL
      break

    case 'marketplace':
      urlConfig = MARKETPLACE_BASE_URL
      break

    case 'partners':
      urlConfig = PARTNERS_BASE_URL
      break

    case 'cloud':
      urlConfig = CLOUD_FUNCTIONS_BASE_URL
      break

    default:
      urlConfig = MARKETPLACE_BASE_URL
      break
  }

  return urlConfig[env?.toUpperCase()]
}
