import cn from 'clsx'
import Link from 'next/link'

interface ButtonProps {
  children?: React.ReactNode
  onClick?: () => void
  className?: string
  icon?: React.ReactNode
  primary?: boolean
  secondary?: boolean
  href?: string
  iconPosition?: 'left' | 'right'
}

const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  className = '',
  icon,
  primary,
  secondary,
  href,
  iconPosition = 'left',
  ...props
}: ButtonProps) => {
  if (href) {
    return (
      <Link
        {...props}
        href={href}
        className={cn('btn', primary && 'btn-primary', secondary && 'btn-secondary', className)}>
        {icon && <span className='text-sm'>{icon}</span>} {children}
      </Link>
    )
  }

  return (
    <button
      {...props}
      onClick={onClick}
      className={cn('btn', primary && 'btn-primary', secondary && 'btn-secondary', className)}>
      {icon && iconPosition === 'left' && <span className='text-sm'>{icon}</span>} {children}
      {icon && iconPosition === 'right' && <span className='text-sm'>{icon}</span>}
    </button>
  )
}

export default Button
