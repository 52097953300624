import { doc, setDoc } from 'firebase/firestore'
import { db } from 'services/FirebaseClient'
import { sendSlackNotification } from 'services/client/SlackNotification'
import { v4 as uuidv4 } from 'uuid'

export const submitBugReportToDB = async payload => {
  try {
    const docRef = doc(db, 'bugReports', uuidv4())

    sendSlackNotification(payload, 'Bug Report')

    await setDoc(docRef, payload)

    return true // Indicate successful submission
  } catch (error) {
    console.error('Error submitting bug report', error)
    return false // Indicate failure
  }
}

export const submitFeedbackToDB = async (
  feedbackMessage,
  user,
  setFeedbackMessage,
  setSubmitted
) => {
  try {
    const docRef = doc(db, 'feedback', uuidv4())

    const payload = {
      message: feedbackMessage,
      user: user?.email,
      name: user?.firstName + ' ' + user?.lastName,
      userId: user?.id,
    }

    sendSlackNotification(payload, 'Feedback Form')

    await setDoc(docRef, payload)

    // Reset state after successful submission
    setFeedbackMessage('')
    setSubmitted(true)

    return true // Indicate successful submission
  } catch (error) {
    console.error('Error submitting feedback', error)
    // Handle error as needed (e.g., show an error message to the user)
    return false // Indicate failure
  }
}
