import { CF_BASE_EW, CLOUD_FUNCTIONS } from 'consts/CloudFunctions'
import HEADER from 'consts/Header'
import MSG from 'consts/Messages'
import ROUTES from 'consts/Routes'

import CAMPAIGNS from './Campaigns'

export const PLACEHOLDER_IMAGE =
  process.env.NEXT_PUBLIC_APP_ENV === 'production'
    ? 'https://firebasestorage.googleapis.com/v0/b/sessami-production.appspot.com/o/userPlaceholder.png?alt=media&token=b74a69f9-13b6-4ba1-ab00-af5bbf90be73'
    : 'https://firebasestorage.googleapis.com/v0/b/sessami-58c65.appspot.com/o/eventPlaceholder.png?alt=media&token=4c69f4ac-80e5-4d31-a708-e5a5d46b46f7'

export const EVENT_PLACEHOLDER_IMAGE = '/assets/placeholder/sessami.png'
// export const EVENT_PLACEHOLDER_IMAGE =
//   process.env.NEXT_PUBLIC_APP_ENV === 'production'
//     ? 'https://firebasestorage.googleapis.com/v0/b/sessami-production.appspot.com/o/eventPlaceholder.png?alt=media&token=b1a8e325-e1ce-4d09-968e-f05fa6d85717'
//     : 'https://firebasestorage.googleapis.com/v0/b/sessami-58c65.appspot.com/o/eventPlaceholder.png?alt=media&token=4c69f4ac-80e5-4d31-a708-e5a5d46b46f7'

export const USER_PLACEHOLDER_IMAGE =
  process.env.NEXT_PUBLIC_APP_ENV === 'production'
    ? 'https://firebasestorage.googleapis.com/v0/b/sessami-58c65.appspot.com/o/userPlaceholder.png?alt=media&token=81abdfee-1ca7-4645-b0eb-de88f618d2a0'
    : 'https://firebasestorage.googleapis.com/v0/b/sessami-58c65.appspot.com/o/userPlaceholder.png?alt=media&token=81abdfee-1ca7-4645-b0eb-de88f618d2a0'

export const DATE_FORMAT = 'DD/MM/YYYY'
export const DATE_FORMAT_SHORT = 'DD/MM'
export const DATE_TIME_FORMAT = 'DD/MM/YYYY hh:mm A'
export const DATE_TIME_SEO_FORMAT = 'YYYY-MM-DDTHH:mm-ss' // suggested seo format '2025-07-21T19:00-05:00'
export const HUMAN_FRIENDLY_DATE_TIME_FORMAT = 'ddd, MMM D, hh:mm A'
export const HUMAN_FRIENDLY_DATE_FORMAT = 'ddd, MMM D, YYYY'
export const MONTH_DAY_FORMAT = 'MMM DD'
export const PRECISE_TIME = 'DD/MM/YYYY hh:mm:ss A'
export const TIME_FORMAT = 'hh:mm A'
export const EVENT_CARD_DATE_FORMAT = 'MMM D, YYYY'

export const UNVERIFIED_EMAIL = 'Please take a moment to verify your email address.'

export const GOOGLE_MAP_LIBRARIES = ['places']

export const MAX_ALLOWED_TICKETS = 20
export const MAX_CHECKOUT_DURATION_MINUTES = 5
export const MAX_IMAGES_PER_EVENT = 6
export const MAX_TICKET_TYPES_PER_EVENT = 20

export const MAX_IMG_SIZE = 10 * 1024 * 1024 //10MB

export const APP_UI_PLATFORMS = ['android', 'iphone', 'ipad', 'ipod']
export const APP_UI_ROUTES = [ROUTES.LOG_IN, ROUTES.SIGN_UP, ROUTES.FORGOT_PASSWORD]

export const SHARE_LINKS = ['facebook', 'twitter', 'instagram', 'website']

export const TICKET_PROCESSING_PERCENTAGE = 6 // 6%
export const TICKET_PROCESSING_FIXED = 0.2 // GBP

export const EURO_COUNTRIES = [
  'IT',
  'FR',
  'DE',
  'ES',
  'PT',
  'GR',
  'IE',
  'AT',
  'BE',
  'BG',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'HU',
  'HR',
  'LT',
  'LU',
  'LV',
  'MT',
  'NL',
  'PL',
  'RO',
  'SE',
  'SI',
  'SK',
]

// Countries whose currencies are supported
// USD & EURO only
export const SUPPORTED_COUNTRIES = ['US', ...EURO_COUNTRIES]

export const REGEX = {
  EMAIL_VALIDATION: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, // i make this case insensitive
}
export const SLUG_REGEX = /^[a-z0-9_-]+$/

export const MAJORITY_SOLD_THRESHOLD = 0.5

export const HEX = {
  primary: '#000033',
  secondary: '#E8336A',
  success: '#31BF60',
  info: '#25C7E5',
  warning: '#F6C74E',
  error: '#F81E1E',
  //
  base100: '#F2F2F7',
  base200: '#E3E3E7',
  base300: '#D3D7D9',
  gshade: '#077B2E',
  glight: '#02e01c',
}

export const SOCIAL_MEDIAS = ['facebook', 'instagram', 'twitter']

export const CHART_ITEMS_THRESHOLD_TO_SHOW_BRUSH = 24

export const STAT_ICON_SIZE = 52

export const FILTER_BUTTONS = [
  { label: 'Past', value: 'past' },
  { label: 'Today', value: 'today' },
  { label: 'Upcoming', value: 'future' },
  { label: 'All', value: 'all' },
]

export const PAYOUT_FILTER_BUTTONS = [
  { label: 'Non PaidOut', value: 'unpaid' },
  { label: 'PaidOut', value: 'paid' },
  { label: 'All', value: 'all' },
]

export { ROUTES, CF_BASE_EW, CLOUD_FUNCTIONS, MSG, HEADER, CAMPAIGNS }
