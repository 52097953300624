import MSG from 'consts/Messages'
import { arrayUnion, doc, getDoc, updateDoc } from 'firebase/firestore'
import { toast } from 'react-toastify'
import { db } from 'services/FirebaseClient'
import { extractPublicInfoFromHostRecord } from 'utils/publicInfoExtractor'

// -----------------------------------------------------------------------------
export * from './venues'
export * from './events'
export * from './orders'
// -----------------------------------------------------------------------------

export const readSellerById = async hostId => {
  const docRef = doc(db, 'users', hostId)
  const docSnapShot = await getDoc(docRef)
  let publicHostRecord = extractPublicInfoFromHostRecord(docSnapShot.data())

  return publicHostRecord
}

export const updateHostById = async (hostId, payload, loading, showToast = true) => {
  try {
    loading.start()

    const docRef = doc(db, 'users', hostId)
    await updateDoc(docRef, payload)
    if (showToast) {
      toast.success(MSG.SUCCESS.SELLER.HOST_INFO_UPD, { toastId: 'host-info-updated' })
    }
  } catch (error) {
    console.error('Error during pre-submission processing', error)
    toast.error(MSG.ERROR.OPERATION_FAILED, { toastId: 'operation-failed' })
  } finally {
    loading.remove()
  }
}

export const addGuestinHostDoc = async (payload, hostId) => {
  if (!payload || !hostId) return

  const docRef = doc(db, 'users', hostId)

  try {
    await updateDoc(docRef, { guests: arrayUnion(payload) })
  } catch (err) {
    console.log('Error adding guest to Firebase', err)
    throw err
  }
}

export const readGuestListById = async (hostId, guestId) => {
  if (!hostId || !guestId) return

  try {
    const docRef = doc(db, 'hosts', hostId)
    const docSnap = await getDoc(docRef)

    if (!docSnap.exists()) {
      return { host: null, guest: null }
    }

    const host = docSnap.data()
    const guest = host?.guests?.find(g => g.uuid === guestId) || {}

    return { host, guest }
  } catch (error) {
    console.error('Error fetching guest data', error)
    throw error
  }
}

export const updateGuests = async (hostId, guestId, host, updatedGuest) => {
  try {
    const docRef = doc(db, 'users', hostId)

    const updatedGuestList = host?.guests?.map(g => {
      if (g.uuid !== guestId) return g

      return { ...updatedGuest, uuid: guestId }
    })

    await updateDoc(docRef, { guests: updatedGuestList })
  } catch (error) {
    console.error('Error updating guest in Firebase', error)
    throw error
  }
}

export const updateHostWidgetColors = async (
  formInput,
  host,
  loading = { start: () => {}, remove: () => {} }
) => {
  try {
    loading.start()
    console.log('formInput', formInput)

    const docRef = doc(db, 'users', host?.id)
    await updateDoc(docRef, { branding: formInput })

    toast.success(MSG.SUCCESS.SELLER.BRANDING_UPDATED, { toastId: 'branding-updated' })
  } catch (error) {
    console.error('Error during custom form handling', error)
    toast.error(MSG.ERROR.OPERATION_FAILED, { toastId: 'operation-failed' })
  } finally {
    loading.remove()
  }
}
