import { AiFillHome } from '@react-icons/all-files/ai/AiFillHome'
import { AiOutlineAreaChart } from '@react-icons/all-files/ai/AiOutlineAreaChart'
import { BiChevronLeft } from '@react-icons/all-files/bi/BiChevronLeft'
import { BiChevronRight } from '@react-icons/all-files/bi/BiChevronRight'
import { BsCalendar2EventFill } from '@react-icons/all-files/bs/BsCalendar2EventFill'
import { BsMegaphoneFill } from '@react-icons/all-files/bs/BsMegaphoneFill'
import { FaRegComments } from '@react-icons/all-files/fa/FaRegComments'
import { FiClock } from '@react-icons/all-files/fi/FiClock'
import { FiSettings } from '@react-icons/all-files/fi/FiSettings'
import { FiUsers } from '@react-icons/all-files/fi/FiUsers'
import { TbMapPins } from '@react-icons/all-files/tb/TbMapPins'
import cn from 'clsx'
import withErrorBoundary from 'components/templates/WithErrorBoundary'
import { IMoneyBill } from 'consts/Icons'
// import { Discount } from 'consts/Icons'
import ROUTES from 'consts/Routes'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveNav, setDrawer, setMegaMenu } from 'store/reducers'

// const icon = '/assets/sessami-logo-white.png'

const HostNavVertical = () => {
  const router = useRouter()
  const dispatch = useDispatch()

  const activeNav = useSelector(s => s?.pr?.ui?.activeNav)
  const isDrawerOpen = useSelector(s => s?.pr?.ui?.isDrawerOpen)
  const megaMenu = useSelector(s => s?.pr?.ui?.megaMenu)

  useEffect(() => {
    const match = NavConfig.filter(i => router.pathname.includes(i?.path))

    if (match.length) {
      dispatch(setActiveNav(match[0]?.path))
      dispatch(setMegaMenu(false))
    }
  }, [router.pathname])

  const toggleMenu = () => {
    dispatch(setMegaMenu(false))
    dispatch(setDrawer(!isDrawerOpen))
  }

  const NavConfig = [
    { label: 'Home', icon: <AiFillHome size={20} />, path: ROUTES.DASHBOARD },
    { label: 'Events', icon: <BsCalendar2EventFill size={20} />, path: ROUTES.EVENTS },
    // { label: 'Memberships', icon: <MdOutlineCardMembership size={20} />, path: ROUTES.MEMBERSHIPS },
    // { label: 'Discounts', icon: <Discount size={20} />, path: ROUTES.DISCOUNTS },
    { label: 'Venues', icon: <TbMapPins size={20} />, path: ROUTES.VENUES },
    { label: 'Comments', icon: <FaRegComments size={20} />, path: ROUTES.COMMENTS },
    { label: 'Guests', icon: <FiUsers size={20} />, path: ROUTES.GUESTS },
    {
      label: 'Waitlists',
      icon: <FiClock size={20} />,
      path: ROUTES.WAITLISTS,
    },
    { label: 'Promote', icon: <BsMegaphoneFill size={20} />, path: ROUTES.PROMOTE },
    { label: 'Earnings', icon: <IMoneyBill size={24} />, path: ROUTES.EARNINGS },
    {
      label: 'Reports',
      icon: <AiOutlineAreaChart size={20} />,
      path: ROUTES.REPORTS_OVERVIEW,
    },
    { label: 'Settings', icon: <FiSettings size={20} />, path: ROUTES.SETTINGS_PROFILE },
  ]

  return (
    <div
      className={cn(
        'fixed left-0 top-0 z-50',
        'flex h-screen flex-col justify-between bg-neutral p-4 lg:flex',
        megaMenu ? 'w-full overflow-x-hidden shadow-xl' : 'hidden',
        isDrawerOpen ? 'lg:w-56' : 'lg:w-20',
        'custom-transition-a',
        '!dark:bg-base-200 bg-neutral'
      )}>
      {/* <div className='flex flex-col items-center justify-center text-center   '>
        <Image
          src={icon}
          width={35}
          height={35}
          alt='Sessami Icon'
          className='m-2'
          onClick={() => setClickCount(clickCount + 1)}
        />
      </div> */}

      {/* Theme Toggle */}
      <button data-toggle-theme='dark,light' data-act-class='ACTIVECLASS'></button>

      <div className='z-50 flex flex-1 flex-col justify-center space-y-2'>
        {NavConfig.map(item => {
          if (item.label === 'Separator')
            return <div key={item.path} className='h-px w-full bg-white bg-opacity-10'></div>

          return (
            <Link
              key={item.label}
              href={item?.path || '#'}
              className={cn(
                'flex items-center justify-start rounded-lg text-white',
                'custom-transition-b cursor-pointer',
                'hover:scale-110 hover:bg-secondary',
                isDrawerOpen ? 'px-4 py-3' : 'tooltip tooltip-right tooltip-secondary p-3',
                activeNav === item.path ? 'bg-secondary' : ''
              )}
              data-tip={item.label}>
              {item?.icon}
              <h2 className={cn(' ml-6', isDrawerOpen ? 'block' : 'lg:hidden')}>{item.label}</h2>
            </Link>
          )
        })}
      </div>

      <button
        className='btn btn-ghost btn-sm my-4 w-full place-self-center text-white  hover:btn-secondary'
        onClick={toggleMenu}>
        {isDrawerOpen ? (
          <BiChevronLeft size={30} className='' />
        ) : (
          <BiChevronRight size={30} className='' />
        )}
      </button>
    </div>
  )
}
export default withErrorBoundary(HostNavVertical)
