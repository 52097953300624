import { combineReducers } from '@reduxjs/toolkit'

import campaignReducer from './campaign'
import chartReducer from './charts'
import confirmation from './confirmation'
import eventsReducer from './events'
import loadingReducer from './loading'
import ordersReducer from './orders'
import tablesReducer from './tables'
import uiReducer from './ui'
import userReducer from './user'

// Store setup
// https://github.com/kirill-konshin/next-redux-wrapper/blob/master/packages/demo-redux-toolkit/store.ts
// ------------- Root Reducer -------------
const rootReducer = combineReducers({
  ui: uiReducer,
  user: userReducer,
  events: eventsReducer,
  orders: ordersReducer,
  charts: chartReducer,
  loading: loadingReducer,
  confirmation: confirmation,
  campaign: campaignReducer,
  tables: tablesReducer,
})

// Export all available actions
export * from './events'
export * from './loading'
export * from './confirmation'
export * from './orders'
export * from './ui'
export * from './user'
export * from './charts'
export * from './campaign'
export * from './tables'

// Export RootReducer
export default rootReducer
