import { FETCH_LIMIT } from 'consts/Firebase'
import MSG from 'consts/Messages'
import { collection, getDocs, limit, orderBy, query, where } from 'firebase/firestore'
import { toast } from 'react-toastify'
import { db } from 'services/FirebaseClient'
import { getCutOffTimestamp } from 'utils/Timestamp'

// #############################################################################
// #                                CREATE                                     #
// #############################################################################

// #############################################################################
// #                                READ                                       #
// #############################################################################
export const readPublicEvents = async (callback = () => {}) => {
  const cutOffLastThreeDays = getCutOffTimestamp('past', 3)
  const ref = query(
    collection(db, 'events'),
    where('isPublished', '==', true),
    where('isPrivate', '==', false),
    where('date', '>=', cutOffLastThreeDays),
    orderBy('date', 'asc'),
    limit(FETCH_LIMIT)
  )

  try {
    const snapshot = await getDocs(ref)
    const events = snapshot.docs.map(doc => doc.data())
    callback(events)
    return events
  } catch (error) {
    toast.error(MSG.ERROR.OPERATION_FAILED, { toastId: 'duplication-error' })
    console.error('Error @fetchAllPublicEvents', error)
  }
}

// #############################################################################
// #                                UPDATE                                     #
// #############################################################################

// #############################################################################
// #                                DELETE                                     #
// #############################################################################
