import cn from 'clsx'
import ReusableGrid from 'components/containers/ReusableGrid'
import BackBar from 'components/molecules/BackBar/BackBar'
import ProfileMenu from 'components/molecules/ProfileMenu'
import UnVerifiedBanner from 'components/molecules/UnVerifiedBanner'
import withErrorBoundary from 'components/templates/WithErrorBoundary'
import { HEADER } from 'consts'
import Link from 'next/link'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const Header = () => {
  return (
    <ReusableGrid className='custom-transition-b absolute right-0 top-0 z-40 w-full bg-base-100'>
      <div className='col-span-full flex items-center justify-between px-4 py-1'>
        <BackBar />
        <div className='flex items-center justify-center space-x-4'>
          <HeaderNav />
          <ProfileMenu />
        </div>
      </div>
      <UnVerifiedBanner />
    </ReusableGrid>
  )
}

const HeaderNav = () => {
  const accessToken = useSelector(s => s?.pr?.user?.auth?.stsTokenManager?.accessToken)
  const [links, setLinks] = useState(HEADER.NAVIGATION)

  useEffect(() => {
    setLinks(accessToken ? HEADER.APP_HOST_NAVIGATION : HEADER.NAVIGATION)
  }, [accessToken])

  // Handle internal and external links. Internal by default
  const LinkCore = ({ item }) => {
    if (item?.externalPath) {
      return (
        <a
          href={item.externalPath}
          target='_blank'
          rel='noreferrer'
          className={cn(
            'flex h-full w-full flex-row items-center text-center',
            item?.icon && 'gap-2'
          )}>
          {item.icon}
          {item.title}
        </a>
      )
    }

    return (
      <Link
        href={item.path || '#'}
        className={cn(
          'flex h-full w-full flex-row items-center text-center',
          item?.icon && 'gap-2',
          HEADER.NEW_ITEMS.includes(item?.title) ? 'btn btn-primary btn-sm mb-1 ' : ''
        )}>
        {item.icon}
        {item.title}
      </Link>
    )
  }

  return (
    <ul className='flex items-center justify-center md:space-x-6'>
      {links.map(item => (
        <li key={item.path} className={item?.className}>
          {item?.subMenu?.length ? (
            <div className='dropdown dropdown-end'>
              <div tabIndex='0' role='button' className='btn btn-ghost btn-sm'>
                {item.title}
                {item.icon}
              </div>
              <ul
                tabIndex='0'
                className='menu dropdown-content z-10 w-52 rounded-box bg-base-200 p-2 shadow'>
                {item.subMenu.map((subItem, index) => (
                  <li key={subItem.path + index}>
                    {/* <Link href={subItem.path ||"#"} className='flex items-center'>
                        {subItem.title}
                      </Link> */}
                    <LinkCore item={subItem} />
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <LinkCore item={item} />
          )}
        </li>
      ))}
    </ul>
  )
}

export default withErrorBoundary(Header)
